/*******************************
        Theme Selection
*******************************/
/* To override a theme for an individual element specify theme name below */
/* Global */
/* Elements */
/* Collections */
/* Modules */
/* Views */
/* Extras */
/*******************************
            Folders
*******************************/
/* Path to theme packages */
/* Path to site override folder */
/*******************************
         Import Theme
*******************************/
/*******************************
        Import Directives
*******************************/
/*------------------
       Theme
-------------------*/
/*--------------------
   Site Variables
---------------------*/
/* Default site.variables */
/*******************************
         Site Settings
*******************************/
/*-------------------
       Fonts
--------------------*/
/*-------------------
      Base Sizes
--------------------*/
/* This is the single variable that controls them all */
/* The size of page text  */
/*-------------------
    Border Radius
--------------------*/
/* See Power-user section below
   for explanation of @px variables
*/
/*-------------------
    Brand Colors
--------------------*/
/*--------------
  Page Heading
---------------*/
/*--------------
   Form Input
---------------*/
/* This adjusts the default form input across all elements */
/* Input Text Color */
/* Line Height Default For Inputs in Browser (Descenders are 17px at 14px base em) */
/*-------------------
    Focused Input
--------------------*/
/* Used on inputs, textarea etc */
/* Used on dropdowns, other larger blocks */
/*-------------------
        Sizes
--------------------*/
/*
  Sizes are all expressed in terms of 14px/em (default em)
  This ensures these "ratios" remain constant despite changes in EM
*/
/*-------------------
        Page
--------------------*/
/*-------------------
      Paragraph
--------------------*/
/*-------------------
       Links
--------------------*/
/*-------------------
    Scroll Bars
--------------------*/
/* Inverted */
/*-------------------
  Highlighted Text
--------------------*/
/*-------------------
       Loader
--------------------*/
/*-------------------
        Grid
--------------------*/
/*-------------------
     Transitions
--------------------*/
/*-------------------
     Breakpoints
--------------------*/
/*-------------------
      Site Colors
--------------------*/
/*---  Colors  ---*/
/*---  Light Colors  ---*/
/*---   Neutrals  ---*/
/*--- Colored Backgrounds ---*/
/*--- Colored Headers ---*/
/*--- Colored Text ---*/
/*--- Colored Border ---*/
/*-------------------
     Alpha Colors
--------------------*/
/*-------------------
       Accents
--------------------*/
/* Differentiating Neutrals */
/* Differentiating Layers */
/*******************************
           Power-User
*******************************/
/*-------------------
    Emotive Colors
--------------------*/
/* Positive */
/* Negative */
/* Info */
/* Warning */
/*-------------------
        Paths
--------------------*/
/* For source only. Modified in gulp for dist */
/*-------------------
       Em Sizes
--------------------*/
/*
  This rounds @size values to the closest pixel then expresses that value in (r)em.
  This ensures all size values round to exact pixels
*/
/* em */
/* rem */
/*-------------------
       Icons
--------------------*/
/* Maximum Glyph Width of Icon */
/*-------------------
     Neutral Text
--------------------*/
/*-------------------
     Brand Colors
--------------------*/
/*-------------------
      Borders
--------------------*/
/*-------------------
    Derived Values
--------------------*/
/* Loaders Position Offset */
/* Rendered Scrollbar Width */
/* Maximum Single Character Glyph Width, aka Capital "W" */
/* Used to match floats with text */
/* Header Spacing */
/* Minimum Mobile Width */
/* Positive / Negative Dupes */
/* Responsive */
/*-------------------
  Exact Pixel Values
--------------------*/
/*
  These are used to specify exact pixel values in em
  for things like borders that remain constantly
  sized as emSize adjusts

  Since there are many more sizes than names for sizes,
  these are named by their original pixel values.

*/
/* Columns */
/*******************************
             States
*******************************/
/*-------------------
      Disabled
--------------------*/
/*-------------------
        Hover
--------------------*/
/*---  Shadows  ---*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
        Focus
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
    Down (:active)
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
        Active
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/* Packaged site.variables */
/*******************************
         Site Settings
*******************************/
/*-------------------
       Fonts
--------------------*/
/*-------------------
      Base Sizes
--------------------*/
/*-------------------
    Border Radius
--------------------*/
/*-------------------
    Brand Colors
--------------------*/
/*--------------
  Page Heading
---------------*/
/*--------------
   Form Input
---------------*/
/* This adjusts the default form input across all elements */
/* Input Text Color */
/*-------------------
    Focused Input
--------------------*/
/*-------------------
        Sizes
--------------------*/
/*
  Sizes are all expressed in terms of 14px/em (default em)
  This ensures these "ratios" remain constant despite changes in EM
*/
/*-------------------
        Page
--------------------*/
/*-------------------
      Paragraph
--------------------*/
/*-------------------
       Links
--------------------*/
/*-------------------
    Scroll Bars
--------------------*/
/*-------------------
  Highlighted Text
--------------------*/
/*-------------------
       Loader
--------------------*/
/*-------------------
        Grid
--------------------*/
/*-------------------
     Transitions
--------------------*/
/*------------------
     Animations
--------------------*/
/*-------------------
     Breakpoints
--------------------*/
/*-------------------
      Site Colors
--------------------*/
/*---  Colors  ---*/
/*---  Light Colors  ---*/
/*---   Neutrals  ---*/
/*--- Colored Backgrounds ---*/
/*--- Colored Headers ---*/
/*--- Colored Text ---*/
/*--- Colored Border ---*/
/*-------------------
     Alpha Colors
--------------------*/
/*-------------------
       Accents
--------------------*/
/*-------------------
      Opacities
--------------------*/
/*******************************
           Power-User
*******************************/
/*-------------------
    Emotive Colors
--------------------*/
/* Positive */
/* Negative */
/* Info */
/* Warning */
/*-------------------
        Paths
--------------------*/
/*-------------------
       Em Sizes
--------------------*/
/* em */
/* rem */
/*-------------------
       Icons
--------------------*/
/*-------------------
     Neutral Text
--------------------*/
/*-------------------
     Brand Colors
--------------------*/
/*-------------------
      Borders
--------------------*/
/*-------------------
    Derived Values
--------------------*/
/* Loaders Position Offset */
/* Rendered Scrollbar Width */
/* Maximum Single Character Glyph Width, aka Capital "W" */
/* Used to match floats with text */
/* Header Spacing */
/* Minimum Mobile Width */
/* Positive / Negative Dupes */
/* Responsive */
/*-------------------
  Exact Pixel Values
--------------------*/
/*
  These are used to specify exact pixel values in em
  for things like borders that remain constantly
  sized as emSize adjusts

  Since there are many more sizes than names for sizes,
  these are named by their original pixel values.

*/
/* Columns */
/*******************************
             States
*******************************/
/*-------------------
      Disabled
--------------------*/
/*-------------------
        Hover
--------------------*/
/*---  Shadows  ---*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
        Focus
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
    Down (:active)
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
        Active
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/* Component's site.variables */
/*******************************
         Site Settings
*******************************/
/*-------------------
       Fonts
--------------------*/
/*-------------------
      Base Sizes
--------------------*/
/*-------------------
    Border Radius
--------------------*/
/*-------------------
    Brand Colors
--------------------*/
/*--------------
  Page Heading
---------------*/
/*--------------
   Form Input
---------------*/
/* This adjusts the default form input across all elements */
/* Input Text Color */
/*-------------------
    Focused Input
--------------------*/
/*-------------------
        Sizes
--------------------*/
/*
  Sizes are all expressed in terms of 14px/em (default em)
  This ensures these "ratios" remain constant despite changes in EM
*/
/*-------------------
        Page
--------------------*/
/*-------------------
      Paragraph
--------------------*/
/*-------------------
       Links
--------------------*/
/*-------------------
    Scroll Bars
--------------------*/
/*-------------------
  Highlighted Text
--------------------*/
/*-------------------
       Loader
--------------------*/
/*-------------------
        Grid
--------------------*/
/*-------------------
     Transitions
--------------------*/
/*------------------
     Animations
--------------------*/
/*-------------------
     Breakpoints
--------------------*/
/*-------------------
      Site Colors
--------------------*/
/*---  Colors  ---*/
/*---  Light Colors  ---*/
/*---   Neutrals  ---*/
/*--- Colored Backgrounds ---*/
/*--- Colored Headers ---*/
/*--- Colored Text ---*/
/*--- Colored Border ---*/
/*-------------------
     Alpha Colors
--------------------*/
/*-------------------
       Accents
--------------------*/
/*-------------------
      Opacities
--------------------*/
/*******************************
           Power-User
*******************************/
/*-------------------
    Emotive Colors
--------------------*/
/* Positive */
/* Negative */
/* Info */
/* Warning */
/*-------------------
        Paths
--------------------*/
/*-------------------
       Em Sizes
--------------------*/
/* em */
/* rem */
/*-------------------
       Icons
--------------------*/
/*-------------------
     Neutral Text
--------------------*/
/*-------------------
     Brand Colors
--------------------*/
/*-------------------
      Borders
--------------------*/
/*-------------------
    Derived Values
--------------------*/
/* Loaders Position Offset */
/* Rendered Scrollbar Width */
/* Maximum Single Character Glyph Width, aka Capital "W" */
/* Used to match floats with text */
/* Header Spacing */
/* Minimum Mobile Width */
/* Positive / Negative Dupes */
/* Responsive */
/*-------------------
  Exact Pixel Values
--------------------*/
/*
  These are used to specify exact pixel values in em
  for things like borders that remain constantly
  sized as emSize adjusts

  Since there are many more sizes than names for sizes,
  these are named by their original pixel values.

*/
/* Columns */
/*******************************
             States
*******************************/
/*-------------------
      Disabled
--------------------*/
/*-------------------
        Hover
--------------------*/
/*---  Shadows  ---*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
        Focus
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
    Down (:active)
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
        Active
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/* Site theme site.variables */
/*******************************
         Site Settings
*******************************/
/*-------------------
       Fonts
--------------------*/
/* This is the single variable that controls them all */
/* The size of page text  */
/*-------------------
    Brand Colors
--------------------*/
/*--------------
  Page Heading
---------------*/
/* 5px @ 13em */
/*-------------------
        Sizes
--------------------*/
/*
  Sizes are all expressed in terms of 16px/em (default em)
  This ensures these "ratios" remain constant despite changes in EM
*/
/*-------------------
        Page
--------------------*/
/*-------------------
     Breakpoints
--------------------*/
/*-------------------
      Site Colors
--------------------*/
/*---  Colors  ---*/
/*--- -------------------------------------------------------------------------------- ---*/
/*--- --------------------------------Plone.org Variables-------------------------------- ---*/
/*--- -------------------------------------------------------------------------------- ---*/
/* TEXT CROP */
/*
 Mixin generated at: http://text-crop.eightshapes.com/?typeface-selection=google-font&typeface=Lato&custom-typeface-name=Helvetica&custom-typeface-url=&custom-typeface-weight=400&custom-typeface-style=normal&weight-and-style=100&size=36&line-height=1.2&top-crop=9&bottom-crop=6

Usage Examples
  .my-level-1-heading-class {
      .text-crop; // Will use default line height of 1.3
      font-size: 48px;
      margin: 0 0 0 16px;
  }

  .my-level-2-heading-class {
      .text-crop; // Will use default line height of 1.3
      font-size: 32px; // Don't need to change any settings, will work with any font size automatically
      margin: 0 0 0 16px;
  }

  .my-body-copy-class {
      .text-crop(@line-height: 2); // Larger line height desired, set the line height via the mixin
      font-size: 16px;
  }

  // Sometimes depending on the font-size, the rendering, the browser, etc. you may need to tweak the output.
  // You can adjust the top and bottom cropping when invoking the component using the $top-adjustment and $bottom-adjustment settings

  .slight-adjustment-needed {
      .text-crop(@top-adjustment: -0.5px, @bottom-adjustment: 2px);
      font-size: 17px;
  }

  .dont-do-this {
      .text-crop;
      font-size: 16px;
      line-height: 3; // DO NOT set line height outside of the mixin, the mixin needs the line height value to calculate the crop correctly
  }
*/
/*-------------------
 Component Variables
---------------------*/
/* Default */
/* Packaged Theme */
/* Site Theme */
/*******************************
             Mix-ins
*******************************/
/*------------------
       Fonts
-------------------*/
/*------------------
     Overrides
-------------------*/
/* End Config */
.block-editor-tabs .block:last-child {
  margin-bottom: 1em;
}
.block-editor-tabs [data-rbd-draggable-context-id] {
  margin-bottom: 1rem;
}
.block-editor-tabs .block-add-button {
  display: none !important;
}
.block-editor-tabs .block.tabs.selected::before,
.block-editor-tabs .block.tabs:hover::before {
  border-style: dashed;
}
.block-editor-tabs fieldset {
  border: none;
}
.block-editor-tabs fieldset legend {
  position: absolute;
  z-index: 3;
  top: -1.3em;
  left: 0;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 1rem;
  margin-right: auto;
  margin-left: auto;
  background-color: #ffffff;
  color: rgba(120, 192, 215, 0.75);
  cursor: pointer;
  text-align: center;
}
.block-editor-tabs .tabs-block {
  padding-top: 1rem;
  padding-bottom: 0.1rem;
  margin: 0;
}
.block-editor-tabs .blocks-form {
  margin-top: 0.5rem;
}
.block-editor-tabs .blocks-chooser {
  right: 0;
  left: auto;
  margin-top: 3rem;
}
.block-editor-tabs .block-toolbar {
  position: absolute;
  z-index: 3;
  right: -9px;
  display: flex;
  border: none;
  border: 1px solid rgba(120, 192, 215, 0.75);
  border-bottom: 1px solid #ffffff;
  margin-top: -45px;
  background-color: #ffffff;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}
.block-editor-tabs .block-toolbar .ui.basic.button {
  padding: 8px 5px;
}
.block-editor-tabs .block-toolbar .ui.basic.button:hover {
  background: transparent !important;
}
.tabs-block .tabs {
  margin-top: 0 !important;
  clear: both;
  /* ensure floated images from content area don't spill over next tabs title */
}
.tabs-block .tabs-title {
  text-transform: none !important;
}
.tabs-block .tabs-title.align-arrow-left {
  padding-left: 0 !important;
}
.tabs-block h1,
.tabs-block h2,
.tabs-block h3,
.tabs-block h4,
.tabs-block h5,
.tabs-block h6 {
  margin: 0 !important;
}
.tabs-block h1 {
  font-size: 2em;
}
.tabs-block h2 {
  font-size: 2em;
}
.tabs-block h3 {
  font-size: 1.5em;
}
.tabs-block h4 {
  font-size: 1.25em;
}
.tabs-block h5 {
  font-size: 1.125em;
}
.tabs-block h6 {
  font-size: 1.125em;
}
.tabs-block .align-arrow-left {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tabs-block .align-arrow-right {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
}
.tabs-block .input-tabs-title {
  width: 100%;
}
.tabs-block .block.selected {
  z-index: 2;
}
.tabs-block .content.active {
  padding: 9px !important;
}
@media print {
  div.rah-static.rah-static--height-zero {
    overflow: visible;
    height: auto !important;
  }
  div.rah-static.rah-static--height-zero > div {
    display: block !important;
    opacity: 1 !important;
  }
}
