div.ui.container.memberActions {
  display: flex;
  align-items: center;
  justify-content: center;
}
div.ui.container.memberActions > .actions {
  text-align: center;
}
button.ui.button.transition {
  padding: 2rem 4rem;
  font-size: 14px;
  font-weight: 700;
}
button.ui.button.transition.transition-Renew {
  background-color: #5eb618;
  color: white;
}
button.ui.button.transition.transition-Retire {
  margin-left: 3rem;
  background-color: #cdcdcd;
  color: black;
}
